// @flow
export type BookViewMode =  'LIBRARY' | 'RESEARCH';

export type IdentifierSet = {
  isbn?: string,
  lccn?: string, // library of congress 
  issn?: string, // international standard serial number
  gutenberg?: string,
  goodreads?: string,
  amazon?: string,
  google?: string,
};

export type Classification = {
  dewey?: string,
  lcc?: string,  // Library of congress classification, used in University libraries
};
export type PublicationInfo = {
  publisher: Array<string>,
  location: Array<string>,
  date: string
};

export type SeriesInfo = {
  name: string,
  number?: number,
};

export type CoverImage = {
  height?: number,
  url: string
};

export type Price = {
  amount?: number,
  currency_code?: string,
};

export type BookPricing = {
  list_price?: Price,
  retailPrice?: Price,
};

export type LinkData = {
  url: string,
  name: string
};

export type FileDownload = {
  id: number,
  format: string
};

export type DetailedRatings = {
    entertainment?: number,
    prose?: number,
    ideas?: number,
    depth?: number,
};

export type StandardBook = {
  key?: string,
  id?: number,
  files: Array<FileDownload>,

  title?: string,
  authors: Array<string>,
  author_sort?: string,
  tags: Array<string>,
  description?: string,
  ids: IdentifierSet,
  classification: Classification,
  publication_info: PublicationInfo,
  series_info?: SeriesInfo,
  language?: string,
  covers: Array<CoverImage>,
  pageCount: number,
  rating: number,
  ratingCount: number,
  detailedRatings: DetailedRatings,
  pricing: BookPricing,
  preview_links: Array<LinkData>,
  download_links: Array<LinkData>,
  other_links: Array<LinkData>,
};


export const displayPrice = (price: Price) => {
  if(! price.currency_code || price.currency_code === 'USD') {
    return "$" + price.amount.toFixed(2);
  }
  return `${price.amount.toFixed(2)} ${price.currency_code}`;
};

export const displayPricing = (pricing: BookPricing) => {
  if( pricing.list_price && pricing.retailPrice && pricing.list_price.amount !== pricing.retailPrice.amount) {
    return `${displayPrice(pricing.list_price)} List, ${displayPrice(pricing.list_price)} Retail`;
  } else if (pricing.list_price) {
    return displayPrice(pricing.list_price);
  } else if (pricing.retailPrice) {
    return displayPrice(pricing.retailPrice);
  }
  return null;
};

export const bookId = (book: StandardBook, index: int) => (book.key || book.id ||  book.title + book.authors + index);
