// @flow

import type { AuthorizedContext } from './AuthContext';

export type QueryParams = {[string]: any};
export type RequestMethod = 'GET' | 'POST';

type QueryInput = {
  onSuccess?: (any) => void,
  onFailure?: (any) => void,
  method: RequestMethod,
  url: string,
  params?: QueryParams,
  body?: QueryParams,
  files?: QueryParams,
  headers?: QueryParams,
};

export const query = ({method, url, params, body, files, headers, onSuccess, onFailure}: QueryInput) => {

  const paramString = params ? '?' + Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&') : '';
  const headerPayload = headers || {};
  headerPayload['Accept'] = 'application/json, text/plain, */*';

  var bodyPayload = null

  if(method !== 'GET' && body != null && files == null) {
    headerPayload['Content-Type'] = 'application/json';
    bodyPayload = JSON.stringify(body);
  }
  if(method !== 'GET' && files != null) {
    bodyPayload = new FormData();
    if (body) {
      Object.keys(body).forEach((key) =>
        bodyPayload.append(key, JSON.stringify(body[key]))
      ); 
    }
    if (files) {
      Object.keys(files).forEach((key) =>
        bodyPayload.append(key, files[key])
      ); 
    }
    console.log(bodyPayload);
  } 

  const payload = method === 'GET' ? {method, headers} : {
    method,
    headers: headerPayload,
    body: bodyPayload
  };

  fetch(url + paramString, payload)
    .then(res => res.json())
    .then(
      (result) => {
        onSuccess && onSuccess(result);
      },
      (error) => {
        onFailure && onFailure(error);
      }
    );
};


export type FormFailures = { [string]: string };

export type MyQueryInput = {
  method: RequestMethod,
  authContext?: AuthorizedContext,
  endpoint: string,
  params?: QueryParams,
  body?: QueryParams,
  files?: QueryParams,
  onSuccess?: (any) => void,
  onFailure?: (data: any, errorCode: ?string, errorMessage: ?string) => void,
  setFormFailures?: (FormFailures) => void

};

export const myQuery = ({method, authContext, endpoint, params, body, files, onSuccess, onFailure, setFormFailures}: MyQueryInput) => {
  const url = '/api/' + endpoint;
  const headers = authContext ? {'Authorization': 'Bearer ' + authContext.token} : undefined;
  var onFailureCallback = onFailure;
  if (setFormFailures) {
    // override onFailureCallback with a thing that sets the state of the form
    onFailureCallback = (data, errorCode, errorMessage) => {
      setFormFailures(data);
    };
  }
  console.log('querying url', url);
  query({
    onSuccess: onSuccess && ((result) => {
      console.log('success response?', result);
      // if response is shaped like {result: 'FAILURE'}, it didn't actually work, even if it returned properly
      if(result !== null && result.result === 'failure') {
        onFailureCallback && onFailureCallback(result.data, result.error_code, result.error_message);
      } else {
        onSuccess(result);
      }
    }),
    onFailure: onFailureCallback && ((error) => {

      console.log('failure response?');
      // all failures *should* look like this but maybe something horrible happened
      if(error.result === 'failure') {
        onFailureCallback(error.data, error.error_code, error.error_message);
      } else {
        onFailureCallback(error);
      }
    }),
    method,
    url,
    params,
    body,
    files,
    headers
  });
}

