// @flow
import React from 'react';

import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

// color scheme: https://coolors.co/e3dfb5-815355-523249-d4b2d8-efcefa


const theme = createMuiTheme({
  typography: {
    fontFamily: '"Cochin", "Times New Roman", serif'
  },
  palette: {
    secondary: {
      light: '#f4f2e1',
      main: '#e3dfb4',
      dark: '#CDC67A',
      contrastText: '#523249',
    },
    primary: {
      light: '#Af83e5',
      main: '#815355',
      dark: '#4b3031',
      contrastText: '#f4f2e1',
    },
    background: {
      default: '#f4f2e1'
    },
  },
});

type Props = {
  children: React$Node,
};

const Theme = ({useAlt, children}: Props) => (

  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default Theme;