// @flow
import React from 'react';

import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSchool, faGraduationCap, faQuestionCircle, faUserFriends, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";

import AuthContext from '../utils/AuthContext';

import './Header.css';

const Header = () => {
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const handleNavClick = React.useCallback((url) => {
    setDrawerOpen(false);
    history.push(url);
  }, [history, setDrawerOpen])

  return (
    <AppBar position="static" className="header" >

      <AuthContext.Consumer>
        {({authContext, logout}) =>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" className="header-menuButton" onClick={() => setDrawerOpen(true)}>
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <Drawer anchor='left' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <List>
                <ListItem button onClick={() => handleNavClick("/library")}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faSchool} />
                  </ListItemIcon>
                  <ListItemText primary='Library' />
                </ListItem>
                <ListItem button onClick={() => handleNavClick("/research")}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faGraduationCap} />
                  </ListItemIcon>
                  <ListItemText primary='Research' />
                </ListItem>

                <ListItem button onClick={() => handleNavClick("/friends")}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faUserFriends} />
                  </ListItemIcon>
                  <ListItemText primary='Friends' />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => handleNavClick("/account")}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCog} />
                  </ListItemIcon>
                  <ListItemText primary='Manage Account' />
                </ListItem>

                <ListItem button onClick={logout}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </ListItemIcon>
                  <ListItemText primary='Sign Out' />
                </ListItem>
                <Divider />
                <ListItem button onClick={() => handleNavClick("/about")}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </ListItemIcon>
                  <ListItemText primary='About' />
                </ListItem>
              </List>
            </Drawer>
            <Typography variant="h6" className="header-title">
              <Button color="inherit" onClick={() => handleNavClick("/")}>Ex Libris de Nubes</Button>
            </Typography>
          </Toolbar>
        }
      </AuthContext.Consumer>
    </AppBar>
  );
};

export default Header;