// @flow
import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import LoadingGif from './components/LoadingGif';
import AuthContext from './utils/AuthContext';
import Header from './components/Header';

import './index.css';

const About          = React.lazy(() =>  import('./pages/about/About'));
const Home           = React.lazy(() =>  import('./pages/home/Home'));
const Friends        = React.lazy(() =>  import('./pages/friends/Friends'));
const Login          = React.lazy(() =>  import('./pages/login/Login'));
const LibraryRouter  = React.lazy(() =>  import('./pages/library/LibraryRouter'));
const Reader         = React.lazy(() =>  import('./pages/library/Reader'));
const ResearchRouter = React.lazy(() =>  import('./pages/research/ResearchRouter'));
const ResetPassword  = React.lazy(() =>  import('./pages/resetPassword/ResetPassword'));
const UserManagement = React.lazy(() =>  import('./pages/user/UserManagement'));

type RouteProps = {
  path: string,
  exact: boolean,
  children: React$Node
};

// const AuthRoute = (routeProps: RouteProps) => (
//   <AuthContext.Consumer>
//     {({authContext}) => (authContext) ? <Route {...routeProps}/> : <Login />}
//   </AuthContext.Consumer>
// );

// const SuperAuthRoute = (routeProps: RouteProps) => (
//   <AuthContext.Consumer>
//     {({authContext}) => authContext && authContext.is_superuser && <Route {...routeProps}/>}
//   </AuthContext.Consumer>
// );


const Routes = () => {
  const {authContext} = React.useContext(AuthContext);

  if (authContext) {
    return (
      <>
        <Header />
        <div className="main-container">
          <Switch>
            <React.Suspense fallback={<LoadingGif/>}>
              <Route path="/about">
                <About />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/library">
                <LibraryRouter />
              </Route>
              <Route path="/reader/:fileId/:title">
                <Reader />
              </Route>
              <Route path="/research">
                <ResearchRouter />
              </Route> 
              <Route path="/account">
                <UserManagement />
              </Route> 
              <Route path="/friends">
                <Friends />
              </Route> 
            </React.Suspense>
          </Switch>
        </div>
      </>
    );
  }

  return (
    <React.Suspense fallback={<LoadingGif/>}>
      <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/login">
            <Login />
          </Route>

          <Route path="/referral/:token">
            <ResetPassword mode="REFERRAL" />
          </Route>
          <Route path="/reset/:token">
            <ResetPassword mode="RESET" />
          </Route>
          <Redirect to="/login" />
      </Switch>
    </React.Suspense>
  );
}
  

export default Routes;