// @flow
import React from 'react';
import { myQuery } from './queryHelpers';
import { StandardBook } from '../components/book/types';
import AuthContext from './AuthContext';

type CountData = {
  value: string,
  count: number
};

export type LibraryResult = {
  books?: StandardBook[],
  tags?: CountData,
  authors?: CountData,
  searchSuggestions?: string[],
  series?: string[],
  failure?: errorString
};

export type LibraryEdit = {
  [bookId: number]: StandardBook | null
};

type Libraries = {[key: number]: LibraryResult};
type LibraryContextState = {
  getLibrary: (userId: number) => ?LibraryResult,
  getMyLibrary: () => ?LibraryResult,
  fetchLibrary: (key: number) => void,
  editLibrary: (libraryEdit: LibraryEdit, userId?: number) => void,

};

const LibraryContext = React.createContext<LibraryContextState>({
  libraries: {},
  fetchLibrary: () => undefined,
  editLibrary: () => undefined,
});


type Props = {children: React$Node};

export const LibraryContextProvider = ({children}: Props) => {
  const [libraries, setLibraries] = React.useState<Libraries>({});
  const { authContext } = React.useContext(AuthContext);

  const updateLibrary = React.useCallback((userId: number, books: StandardBook[]) => {
    const thisLibrary = {books};
      const [tagMap, authorMap, seriesMap] = books.reduce(([tagMap, authorMap, seriesMap], book: StandardBook) => {
        for(let tag of book.tags) {
          tagMap[tag] = tag in tagMap ? tagMap[tag] + 1 : 1;
        }
        for(let author of book.authors) {
          authorMap[author] = author in authorMap ? authorMap[author] + 1 : 1;
        }
        if (book.series_info) {
          seriesMap[book.series_info.name] = true;
        }
        return [tagMap, authorMap, seriesMap];
      }, [{},{}, {}]);
      thisLibrary.tags = Object.keys(tagMap).map((tag) => ({value: tag, count: tagMap[tag]}));
      thisLibrary.authors = Object.keys(authorMap).map((author) => ({value: author, count: authorMap[author]}));
      thisLibrary.series = Object.keys(seriesMap);
      thisLibrary.searchSuggestions = thisLibrary.books.map(book => book.title)
                                                 .concat(Object.keys(tagMap))
                                                 .concat(Object.keys(authorMap))
                                                 .sort();
      setLibraries({
        ...libraries,
        [userId]: thisLibrary
      });
  }, [setLibraries, libraries]);

  const fetchLibrary = React.useCallback((userId: number) => {
    myQuery({
      method: 'GET',
      authContext,
      endpoint: 'library/get_library',
      params: {user_id: userId},
      onSuccess: (response) => {
        setLibraries({
          ...libraries,
          [userId]: {books: response.books}
        });
        updateLibrary(userId, response.books);
      },
      onFailure: () => {
        setLibraries({
          ...libraries,
          [userId]: {failure: 'This library is unavailable'}
        });
      }
    });
  }, [authContext, libraries, setLibraries, updateLibrary]);

  const editLibrary = React.useCallback((libraryEdit: LibraryEdit, userId?: number) => {
    const uid = userId || authContext.id;
    if (libraries[uid] == null) {
      return;
    }
    const library = libraries[uid];
    const newLibraryBooks = library.books.map((book) => {
      if (libraryEdit[book.id] === undefined) {
        return book;
      }
      if (libraryEdit[book.id] === null) {
        return null;
      }
      return libraryEdit[book.id];
    }).filter(book => book != null);

    setLibraries({
      ...libraries,
      [uid]: {
        ...library,
        books: newLibraryBooks
      }
    });
  }, [libraries, setLibraries, authContext]);

  const getLibrary = (userId: number) => libraries[userId];
  const getMyLibrary = () => libraries[authContext.id];


  return (
    <LibraryContext.Provider value={{getLibrary, getMyLibrary, fetchLibrary, editLibrary}}>
      {children}
    </LibraryContext.Provider>
  );
};

export default LibraryContext;



